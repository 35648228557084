import React from 'react';
import { Helmet } from 'react-helmet';
import { headData } from '../mock/data';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../style/main.scss';

export default () => {
  const { title, lang, description } = headData;

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title || 'Brand Soda System'}</title>
        <html lang={lang || 'pl'} />
        <meta name="description" content={description || 'Brand Soda System'} />
        <meta
          name="keywords"
          content="BSS, Brand Soda System, bssoda, sodowanie, szkiełkowanie, piaskowanie, malowanie, oczyszczanie, tynkowanie maszynowe, anty korozja, murowanie"
        />
      </Helmet>
      
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="error-template">
              <h1>Ups! </h1>
              <h2>404 Nie odnaleziono strony</h2>
              <div className="error-details">
                Niestety nie ma takiej strony
              </div>
              <div className="error-actions">
                <a href="https://bssoda.pl" className="cta-btn cta-btn--hero">
                  <span className="glyphicon glyphicon-home" />
                  Wróć do strony głównej{' '}
                </a>{' '}
                <a href="https://bssoda.pl/kontakt" className="cta-btn cta-btn--hero">
                  <span className="glyphicon glyphicon-envelope" /> Kontakt{' '}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
